import React, { useEffect, useState } from "react";
import "../../shared/imports/_variables.css";
import "./membership.css";
import { useNavigate } from "react-router-dom";
import useLoggedInUser from "../../hooks/useLoggedInUser";
import { useParams } from "react-router-dom";
import {
  GetMembershipsResponse,
  GetMemberships,
} from "../../services/api/getMemberships";
import { joinMembership } from "../../services/api/joinMembership";
import openNotification from "../../Components/Antd/Notification/Notification";
import { programSlice } from "../../store/slices/data";
import { authSlice } from "../../store/slices/auth";
import { useDispatch } from "react-redux";
import getSelectedProgram from "../../hooks/useSelectedProgram";
import Loading from "../../Components/loading/loading";
import Cookies from "js-cookie";
import { version } from "./../../utils/version";

const getMembership = async (loggedInUserUuid: string, programUuid: string) => {
  const memberships = await GetMemberships(loggedInUserUuid);

  return memberships.find((x) => x.uuid === programUuid);
};

const MembershipPage: React.FC = () => {
  const loggedInUser = useLoggedInUser();
  const { uuid } = useParams<{ uuid: string }>();
  const { referrer = "" } = useParams<{ referrer: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [membership, setMembership] = useState<GetMembershipsResponse[0]>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [program, setProgram] = useState({
    uuid: "1",
    name: "2",
    description: "3",
    isSelected: false,
    brandLogo: "4",
    brandColor: "5",
    placesDetail: "6",
    brandPattern: "7",
  });

  useEffect(() => {
    console.log("Program uuid", uuid, "Referrer uuid", referrer);
    if (referrer) {
      if (referrer.startsWith("locationID=")) {
        const locationID = referrer.split("=")[1];
        console.log("Location ID found:", locationID);
        localStorage.setItem("locationID", locationID);
        // Handle locationID if needed
      } else {
        // const referrer = referrer;
        console.log("Referrer found:", referrer);
        // Handle referrer if needed
        localStorage.setItem("referrer", referrer);
      }
    }
    if (!uuid || !loggedInUser?.uuid) {
      console.log("No uuid or user");
      return;
    }

    setIsLoading(true);
    getMembership(loggedInUser.uuid, uuid)
      .then(async (membership) => {
        if (membership) {
          setMembership(membership);
          const newProgram = {
            uuid: membership?.uuid || "",
            name: membership?.name || "",
            description: membership?.description || "",
            isSelected: true,
            brandLogo: membership?.imageURL || "",
            brandColor: membership?.brandColor || "",
            placesDetail: membership?.placesDetail || "",
            brandPattern: membership?.brandPattern || "",
          };
          setProgram(newProgram);
          dispatch(programSlice.actions.addProgram(newProgram));
          navigate(`/home`);
          setIsLoading(false);
          return;
        }

        try {
          await joinMembership({
            membershipUuid: uuid,
            shopperUuid: loggedInUser.uuid,
            ...(referrer && { referrerShopperUuid: referrer }),
            locationId: localStorage.getItem("locationID")
              ? Number(localStorage.getItem("locationID"))
              : undefined,
          });
          setIsLoading(false);
          localStorage.setItem("newMembership", "true");
          const newMembership = await getMembership(loggedInUser.uuid, uuid);
          setMembership(newMembership);
          setProgram({
            uuid: newMembership?.uuid || "",
            name: newMembership?.name || "",
            description: newMembership?.description || "",
            isSelected: true,
            brandLogo: newMembership?.imageURL || "",
            brandColor: newMembership?.brandColor || "",
            placesDetail: newMembership?.placesDetail || "",
            brandPattern: newMembership?.brandPattern || "",
          });
        } catch (e) {
          openNotification(
            "error",
            `Failed to join membership for the program`,
            null,
          );
          if (uuid) {
            localStorage.setItem("MembershipUuid", uuid);
          }
          navigate(`/`);
        }
        setIsLoading(false);
      })
      .catch(() => {
        openNotification(
          "error",
          `Session expired, please login again or open a new tab.`,
          null,
        );
        if (uuid) {
          localStorage.setItem("MembershipUuid", uuid);
        }
        dispatch(authSlice.actions.logout());
        navigate(`/`);
      });
  }, [loggedInUser?.uuid, uuid]);

  useEffect(() => {
    if (program.uuid !== "1") {
      dispatch(programSlice.actions.addProgram(program));
      localStorage.removeItem("MembershipUuid");
      navigate(`/home`);
    }
  }, [program, navigate]);
  return (
    <div className="pwa-page">
      <Loading />
      {/* <Container className="membership">
        {isLoading ? <h3>Loading...</h3> : <MembershipCard />}
      </Container>
      <PWANav /> */}
    </div>
  );
};

export default MembershipPage;
