import React, { useState, useRef, useEffect } from "react";
import { Button, Upload, message, Card, Spin, Modal } from "antd";
import {
  CameraOutlined,
  SyncOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "./cameraApp.css";

const CameraApp: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isFrontCamera, setIsFrontCamera] = useState(false);
  const [previewSrc, setPreviewSrc] = useState<string | null>(null);
  const [imageDataUrl, setImageDataUrl] = useState<string | null>(null); // Save captured or selected image data
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Initialize the camera
  const startCamera = async () => {
    try {
      const constraints = {
        video: {
          facingMode: isFrontCamera ? "user" : { exact: "environment" },
        },
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      message.error("Error accessing the camera");
      console.error("Error accessing the camera: ", err);
    }
  };

  // Take a picture and display preview
  const takePicture = () => {
    const canvas = document.createElement("canvas");
    if (videoRef.current) {
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      const dataUrl = canvas.toDataURL("image/png");
      setImageDataUrl(dataUrl); // Save captured image data
      setPreviewSrc(dataUrl); // Set preview
      setIsModalVisible(true); // Show modal
    }
  };

  // Upload image to the server
  const uploadImage = async () => {
    if (!imageDataUrl) {
      message.error("Please take a photo or select an image");
      return;
    }

    setLoading(true); // Start uploading
    const blob = await (await fetch(imageDataUrl)).blob();
    const formData = new FormData();
    formData.append("file", blob, "photo.png");

    // Upload to the server
    try {
      const response = await fetch("/api/upload", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const result = await response.json(); // Assume server returns new image URL
        message.success("Image uploaded successfully");

        // If server returns an image URL, update the preview
        if (result.imageUrl) {
          setPreviewSrc(result.imageUrl); // Update preview with the new image URL
          setImageDataUrl(null); // Clear the previous image data URL
        }
      } else {
        message.error("Failed to upload image");
      }
    } catch (error) {
      message.error("Error uploading image");
    } finally {
      setLoading(false); // Finish uploading
    }
  };

  // Switch between front and back cameras
  const switchCamera = () => {
    setIsFrontCamera(!isFrontCamera);
  };

  // Handle file selection from local gallery
  const handleFileChange = (file: File) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result as string;
        setImageDataUrl(dataUrl); // Save the selected image data
        setPreviewSrc(dataUrl); // Set selected image as preview
        setIsModalVisible(true); // Show modal
      };
      reader.onerror = (error) => {
        console.error("File reading error:", error);
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    } else {
      message.error("No file selected."); // Show error message
      console.error("No file selected or file is not a Blob.");
    }
  };

  useEffect(() => {
    startCamera();
  }, [isFrontCamera]);

  return (
    <div className="camera-app">
      <Card className="camera-card" hoverable>
        <video ref={videoRef} className="video-stream" autoPlay playsInline />
        <div className="button-group">
          <Button
            type="primary"
            icon={<SyncOutlined />}
            onClick={switchCamera}
            className="animated-button"
          >
            Switch Camera
          </Button>
          <Button
            type="primary"
            icon={<CameraOutlined />}
            onClick={takePicture}
            className="animated-button"
          >
            Take Photo
          </Button>
        </div>
        <Upload
          name="file"
          showUploadList={false}
          customRequest={({ file }) => handleFileChange(file as File)} // Pass file directly
          accept="image/*"
        >
          <Button icon={<UploadOutlined />}>Select from Gallery</Button>
        </Upload>
      </Card>

      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <div className="camera-modal-content">
          {loading ? (
            <Spin size="large" className="loading-spinner" />
          ) : (
            previewSrc && (
              <img
                src={previewSrc}
                alt="Preview"
                className="preview-image animated"
              />
            )
          )}

          <Button
            type="primary"
            icon={<UploadOutlined />}
            onClick={uploadImage}
            disabled={!previewSrc || loading}
            className="upload-button"
          >
            Upload Photo
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default CameraApp;
