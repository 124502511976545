import backendImageApi from "./backendImageApi";
import { GetImage } from "./getImage";

export interface ProgramInfo {
  logoUrl: Promise<string>;
  programData: any; // Replace `any` with the actual type of program data if known
}

export const GetProgramLogo = async (uuid: string): Promise<ProgramInfo> => {
  console.log("GetImage", uuid);
  const response = await backendImageApi.get(
    `v2/membership/program/${uuid}`,
    {},
  );
  console.log("objectUrl", response.data["logoImageSha"]);
  console.log("programInfo", response.data);

  const newlogoUrl = GetImage(response.data["logoImageSha"]);
  let programInfo = null;

  if (response.data["welcomeVoucher"] != null) {
    programInfo = GetProgramInfo(response.data);
  }

  return {
    logoUrl: newlogoUrl,
    programData: programInfo,
  };
};

export const GetProgramInfo = (programData: any) => {
  // Extract and return the necessary program information
  // Replace `any` with the actual type of program data if known
  return {
    // Example: extract specific fields from programData
    backgroundImageId: programData["programDetails"]["imageURL"],
    imageId: programData["welcomeVoucher"]["imageId"],
    itemName: programData["welcomeVoucher"]["campaignName"],
    programName: programData["programDetails"]["name"],
    valueDescription: programData["welcomeVoucher"]["valueDescription"],
    branColor: programData["programDetails"]["brandColor"],
    // Add other fields as needed
  };
};
