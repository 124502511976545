import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

type JoinMembershipPayload = {
  shopperUuid: string;
  membershipUuid: string;
  locationId?: number;
  referrerShopperUuid?: string;
};

type JoinMembershipResponse = {};

export const joinMembership = async (
  payload: JoinMembershipPayload,
): Promise<JoinMembershipResponse> => {
  // Destructure the optional parameters and the rest of the payload
  const { referrerShopperUuid, locationId, ...restPayload } = payload;

  // Create the final payload conditionally including optional parameters
  const finalPayload: JoinMembershipPayload = {
    ...restPayload,
    ...(referrerShopperUuid && { referrerShopperUuid }),
    ...(locationId && { locationId }),
  };
  const response = await backendApi.post<
    any,
    AxiosResponse<JoinMembershipResponse>,
    JoinMembershipPayload
  >("v2/membership/join", finalPayload);

  console.log(finalPayload);
  console.log("Join Membership API Call");
  console.log(response.data);
  return response.data;
};
