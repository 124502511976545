import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

export type GetVoucherDetailResponse = {
  id: number;
  code: string;
  startDate: string;
  expiryDate: string;
  isValid: boolean;
  valueDescription: string;
  campaignName: string;
  voucherType: string;
  eventAddress: string;
  giveawayAddress: string;
  imageId: string;
  backgroundColor: string;
  unlockVisitThreshold: number;
  recordedVisits: number;
  termsNConditions: string;
  description: string;
  requirements: { requirements: [{ requirement: string }] };
  Program: {
    uuid: string;
    name: string;
    physicalAddress: string;
    brandColor: string;
    imageURL: string;
  };
};

export const GetVoucherDetail = async (
  uuid: string,
): Promise<GetVoucherDetailResponse> => {
  const response = await backendApi.get<
    any,
    AxiosResponse<GetVoucherDetailResponse, {}>
  >(`v2/voucher/code/${uuid}`);

  const data = response.data;
  // console.log(`v2/voucher/code/${uuid}`);
  // console.log(`response data: ${data}`);
  // console.log(response.data);
  return response.data;
};
