import React, { useState } from "react";
import QRScanner from "./Components/QR_Scanner/QRscanner";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "normalize.css";
import "./pwa-page.css";
// import PWANav from "./Components/pwa-nav/pwa-nav";
// import PWAHeader from "./Components/pwa-header/pwa-header";
import Home from "./Pages/home/home";
import HomeItem from "./Pages/home-item/home-item";
import About from "./Pages/about/about";
import Venue from "./Pages/venue/venue";
import Meed from "./Pages/meed/meed";
import Login from "./Pages/auth/login";
import Verification from "./Pages/auth/verification";
import Name from "./Pages/auth/name";
import RequireAuthenticationLayout from "./Components/RequireAuthenticationLayout";
import Profile from "./Pages/profile/profile";
import MembershipPage from "./Pages/membership/membership";
import Discovery from "./Pages/discovery/discovery";
import Program from "./Pages/program/program";
import EmailOpt from "./Pages/auth/emailOpt";
import EmailVerify from "./Pages/EmailVerify/emailVerify";
//import Status from "./Components/EmailVerify/status";
import WellcomeMeed from "./Pages/auth/welcomeBusiness";
import AccountCreation from "./Pages/auth/accountCreation";
import CameraApp from "./Pages/camera/cameraPage";

function App() {
  const [qrData, setQrData] = useState("");

  const handleScannedData = (data: string) => {
    console.log("Scanned data received in parent:", data);
    setQrData(data);
  };
  return (
    <>
      <BrowserRouter>
        {/* <PWAHeader/> */}
        <Routes>
          {/* Unprotected routes */}
          <Route path="/" element={<WellcomeMeed />}></Route>
          <Route path="/verify" element={<Verification />}></Route>
          <Route path="/accountCreation" element={<AccountCreation />} />
          <Route path="/CameraApp" element={<CameraApp />} />

          {/* Protected routes */}
          <Route element={<RequireAuthenticationLayout />}>
            <Route path="/home" element={<Home />} />
            <Route path="/home-item" element={<HomeItem />} />
            <Route path="/location" element={<Venue />} />
            <Route path="/about" element={<About />} />
            <Route path="/meed" element={<Meed />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/discovery" element={<Discovery />} />
            <Route
              path="/qr"
              element={<QRScanner onDataScan={handleScannedData} />}
            />
            <Route path="/name" element={<Name />} />
            <Route path="/emailOpt" element={<EmailOpt />} />
            <Route path="/program" element={<Program />} />
            <Route path="/emailVerify" element={<EmailVerify />} />
            <Route path="/wellcome" element={<WellcomeMeed />} />
            <Route
              path="/membership/:uuid"
              element={<MembershipPage />}
            ></Route>
            <Route
              path="/membership/:uuid/:referrer"
              element={<MembershipPage />}
            ></Route>
          </Route>
        </Routes>
        {/* <PWANav/> */}
      </BrowserRouter>
      {/*
      QR.
      It will be added later
      A very vital part:
      <h1>QR Code Scanner</h1>
      <div style={{ width: "500px" }}>
        <QRScanner onDataScan={handleScannedData} />
      </div>
      {qrData &&  <p>Scanned QR Code: <h3>{qrData}</h3></p>} */}
    </>
  );
}

export default App;
