import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import TabsAlikeNav from "../../Components/tabs-alike-nav/tabs-alike-nav";
import MembershipCard from "../../Components/membership-card/membership-card";
import VoucherCard from "../../Components/voucher-card/voucher-card";
import Null from "../../Components/null/null";
import { animate } from "motion";
import { motion } from "framer-motion";

import "../../shared/imports/_variables.css";
import "../../shared/imports/hardcoded-components/timer.css";
import "../../shared/imports/hardcoded-components/location.css";
import "../../shared/imports/hardcoded-components/deal.css";
import "../../Components/pwa-header/pwa-header.css";
import "./home.css";
import { AppRoute } from "../../shared/const";
import PWAHeader from "../../Components/pwa-header/pwa-header";
import PWANav from "../../Components/pwa-nav/pwa-nav";
import Container from "../../Components/container/container";
import openNotification from "../../Components/Antd/Notification/Notification";
import getLoggedInUser from "../../hooks/useLoggedInUser";
import { GetVoucher, GetVoucherResponse } from "../../services/api/getVouchers";
import getSelectedProgram from "../../hooks/useSelectedProgram";
import PopUpModal from "../../Components/Antd/Modal/Modal";
import useGetVerified from "../../hooks/getVerified";
import { selfStamp } from "../../services/api/selfStamp";
import { Modal } from "antd";

function Home() {
  const [selectedTab, setSelectedTab] = useState("All");
  const [showPopup, setShowPopup] = useState(false);
  const loggedInUser = getLoggedInUser();
  const selectedProgram = getSelectedProgram();
  const [isLoading, setIsLoading] = useState(false);
  const [vouchers, setVouchers] = useState<GetVoucherResponse>();
  const [voucherCount, setVoucherCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalMessage2, setModalMessage2] = useState("");

  const getVerified = useGetVerified();

  useEffect(() => {
    getVerified();
  });

  const handleCloseModal = () => {
    setModalVisible(false);
    handleGetVoucher();
  };

  useEffect(() => {
    animate(".home__card", {
      // Keyframes (array or single value).
      opacity: [0, 1],
    });
  }, []);
  // call handleGetMemberships when the page loads
  const handleGetVoucher = useCallback(async () => {
    setIsLoading(true);
    setVouchers([]);

    try {
      const response = await GetVoucher(loggedInUser?.uuid ?? "");
      setVouchers(response);

      let totalVoucherCount = response.reduce((total, voucher) => {
        if (
          voucher.Program.uuid === selectedProgram?.uuid &&
          voucher.availableRedemptionCount !== 0
        ) {
          return total + voucher.availableRedemptionCount;
        } else {
          return total;
        }
      }, 0);
      setVoucherCount(totalVoucherCount);

      // Add this block
      const locationID = localStorage.getItem("locationID");
      if (locationID) {
        response.forEach(async (voucher) => {
          if (voucher.memberSelfStamping) {
            try {
              const apiResponse = await selfStamp({
                shopperUuid: loggedInUser?.uuid ?? "",
                programUuid: voucher.Program.uuid,
                locationId: Number(locationID),
              });
              console.log(apiResponse); // Log the response
              localStorage.removeItem("locationID");
              setModalMessage("Success");
              if (apiResponse.success) {
                setModalMessage("Success");
              } else {
                setModalMessage(
                  `You have already checked-in within the last 24 hours.`,
                );
                setModalMessage2(
                  `Next check-in available in ${apiResponse.waitingTime}`,
                );
              }
              setModalVisible(true);
            } catch (err) {
              console.error(
                `Failed to call API for voucher ${voucher.id}`,
                err,
              );
              setModalMessage("An unexpected error occurred.");
              setModalVisible(true);
            }
          }
        });
      } else {
        console.log("No location ID");
        localStorage.removeItem("locationID");
      }
    } catch (err) {
      console.error(err);
      openNotification("error", `Fail to load vouchers`, null);
    } finally {
      setIsLoading(false);
    }
  }, [loggedInUser, selectedProgram]);

  useEffect(() => {
    handleGetVoucher();
  }, [handleGetVoucher]);
  //console.log(selectedProgram);
  useEffect(() => {
    if (localStorage.getItem("newMembership") === "true") {
      setShowPopup(true);
      localStorage.removeItem("newMembership");
    }
  }, []);
  let sortedVouchers = (vouchers ?? []).sort((a, b) => {
    if (a.unlockVisitThreshold !== 0 && b.unlockVisitThreshold === 0) {
      return -1;
    }
    if (a.unlockVisitThreshold === 0 && b.unlockVisitThreshold !== 0) {
      return 1;
    }
    return 0;
  });
  const today = new Date();

  if (selectedTab === "New") {
    sortedVouchers = sortedVouchers
      .filter((voucher) => {
        const startDate = new Date(voucher.startDate);
        const diffInDays = Math.ceil(
          (today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
        );
        return diffInDays <= 7;
      })
      .sort(
        (a, b) =>
          new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
      );
  } else if (selectedTab === "Expires Soon") {
    sortedVouchers = sortedVouchers
      .filter((voucher) => {
        const expiryDate = new Date(voucher.expiryDate);
        const diffInDays = Math.ceil(
          (expiryDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24),
        );
        return diffInDays <= 7;
      })
      .sort(
        (a, b) =>
          new Date(a.expiryDate).getTime() - new Date(b.expiryDate).getTime(),
      );
  }
  console.log(sortedVouchers);

  sortedVouchers = sortedVouchers.filter(
    (voucher) => voucher.Program.uuid === selectedProgram?.uuid,
  );
  // console.log(selectedTab);
  const sortedVouchersWithRemainder = sortedVouchers.map((voucher) => ({
    ...voucher,
    remainder: voucher.recordedVisits % voucher.unlockVisitThreshold,
  }));
  sortedVouchersWithRemainder.sort((a, b) => b.remainder - a.remainder);

  return (
    <>
      <Modal
        title="Check-In Status"
        open={modalVisible}
        onOk={() => handleCloseModal()}
        onCancel={() => handleCloseModal()}
      >
        <p>{modalMessage}</p>
        <p>{modalMessage2}</p>
      </Modal>
      <div className="pwa-page">
        <PWAHeader />
        {showPopup && (
          <PopUpModal
            brandName={selectedProgram?.name}
            logo={selectedProgram?.brandLogo}
          />
        )}

        <Container className="home">
          <div className="home__card">
            <MembershipCard
              backgroundColor={selectedProgram?.brandColor}
              voucherCount={voucherCount}
              brandPattern={selectedProgram?.brandPattern || "None"}
            />
          </div>
          <div className="home__filters">
            <TabsAlikeNav
              brandColor={selectedProgram?.brandColor || ""}
              onTabSelect={setSelectedTab}
            />
          </div>
          <div className="home__list">
            {sortedVouchersWithRemainder.map((voucher, index) => {
              const vouchersArray = Array(
                voucher.availableRedemptionCount,
              ).fill(null);
              const extraVoucherNeeded = voucher.remainder !== 0 ? 1 : 0;
              const totalVouchers = vouchersArray.concat(
                Array(extraVoucherNeeded).fill(null),
              );
              console.log(totalVouchers);
              return totalVouchers.map((_, i) => {
                if (
                  voucher.unlockVisitThreshold !== 0 &&
                  voucher.recordedVisits >= voucher.unlockVisitThreshold
                ) {
                  return (
                    <motion.div
                      key={`${voucher.id}-${i}`}
                      animate={{ opacity: [0, 1] }}
                      transition={{
                        duration: 0.8,
                        delay: index * 0.1,
                        ease: "easeInOut",
                      }}
                      className="home__item"
                    >
                      <VoucherCard
                        key={`${voucher.id}-${i}`}
                        brandName={voucher.Program.name}
                        startDate={voucher.startDate}
                        expriyDate={voucher.expiryDate}
                        discount={voucher.valueDescription}
                        product={voucher.campaignName}
                        location={voucher.Program.physicalAddress}
                        backgroundColor={voucher.Program.brandColor}
                        voucherImage={voucher.imageId}
                        brandImage={voucher.Program.imageURL}
                        textColor="#000000"
                        unlockVisitThreshold={voucher.unlockVisitThreshold}
                        recordedVisits={
                          i === totalVouchers.length - 1 && extraVoucherNeeded
                            ? voucher.remainder
                            : voucher.recordedVisits
                        }
                        code={voucher.code}
                        programUuid={voucher.Program.uuid}
                      />
                    </motion.div>
                  );
                }
                // else {
                //   return (
                //     <motion.div
                //       key={`${voucher.id}-${i}`}
                //       animate={{ opacity: [0, 1] }}
                //       transition={{
                //         duration: 0.8,
                //         delay: index * 0.1,
                //         ease: "easeInOut",
                //       }}
                //       className="home__item"
                //     >
                //       <VoucherCard
                //         key={`${voucher.id}-${i}`}
                //         brandName={voucher.Program.name}
                //         startDate={voucher.startDate}
                //         expriyDate={voucher.expiryDate}
                //         discount={voucher.valueDescription}
                //         product={voucher.campaignName}
                //         location={voucher.Program.physicalAddress}
                //         backgroundColor={voucher.Program.brandColor}
                //         voucherImage={voucher.imageId}
                //         brandImage={voucher.Program.imageURL}
                //         textColor="#000000"
                //         unlockVisitThreshold={voucher.unlockVisitThreshold}
                //         recordedVisits={voucher.remainder}
                //         code={voucher.code}
                //         programUuid={voucher.Program.uuid}
                //       />
                //     </motion.div>
                //   );
                // }
                return null;
              });
            })}
            {sortedVouchers.length > 0 ? (
              (console.log(sortedVouchers),
              sortedVouchers.map((voucher, index) => {
                if (voucher.unlockVisitThreshold === 0) {
                  return (
                    <motion.div
                      key={voucher.id}
                      animate={{ opacity: [0, 1] }}
                      transition={{
                        duration: 0.8,
                        delay: index * 0.1,
                        ease: "easeInOut",
                      }}
                      className="home__item"
                    >
                      <VoucherCard
                        key={voucher.id}
                        brandName={voucher.Program.name}
                        startDate={voucher.startDate}
                        expriyDate={voucher.expiryDate}
                        discount={voucher.valueDescription}
                        product={voucher.campaignName}
                        location={voucher.Program.physicalAddress}
                        backgroundColor={voucher.Program.brandColor}
                        voucherImage={voucher.imageId}
                        brandImage={voucher.Program.imageURL}
                        textColor="#000000"
                        unlockVisitThreshold={voucher.unlockVisitThreshold}
                        recordedVisits={voucher.recordedVisits}
                        code={voucher.code}
                        programUuid={voucher.Program.uuid}
                      />
                    </motion.div>
                  );
                } else if (
                  voucher.unlockVisitThreshold !== 0 &&
                  voucher.availableRedemptionCount === 0 &&
                  voucher.recordedVisits <= voucher.unlockVisitThreshold
                ) {
                  console.log(sortedVouchers);
                  console.log(voucher);
                  return (
                    <motion.div
                      key={voucher.id}
                      animate={{ opacity: [0, 1] }}
                      transition={{
                        duration: 0.8,
                        delay: index * 0.1,
                        ease: "easeInOut",
                      }}
                      className="home__item"
                    >
                      <VoucherCard
                        key={voucher.id}
                        brandName={voucher.Program.name}
                        startDate={voucher.startDate}
                        expriyDate={voucher.expiryDate}
                        discount={voucher.valueDescription}
                        product={voucher.campaignName}
                        location={voucher.Program.physicalAddress}
                        backgroundColor={voucher.Program.brandColor}
                        voucherImage={voucher.imageId}
                        brandImage={voucher.Program.imageURL}
                        textColor="#000000"
                        unlockVisitThreshold={voucher.unlockVisitThreshold}
                        recordedVisits={voucher.recordedVisits}
                        code={voucher.code}
                        programUuid={voucher.Program.uuid}
                      />
                    </motion.div>
                  );
                }
                return null;
              }))
            ) : (
              <Null>Join a loyalty program</Null>
            )}
          </div>
        </Container>

        <PWANav />
      </div>
    </>
  );
}

export default Home;
